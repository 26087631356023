<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'OrganizationHome',
              }"
            >
              組織資料設定
            </b-breadcrumb-item>
            <b-breadcrumb-item active>API Token 管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="d-flex flex-column flex-xl-row align-items-start align-items-xl-center justify-content-between mb-4 mb-xl-2">
        <h4 class="mb-2 mb-xl-0 font-weight-bold">
          API Token 管理
        </h4>
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-form-select
            class="mr-3 mb-2 mb-xl-0"
            v-model="query.merchant_id"
            :options="merchants"
          ></b-form-select>

          <b-button
            v-if="checkPermission([consts.TOKEN_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{ name: 'TokenCreate', query: { merchant_id: query.merchant_id } }"
          ><i class="fa fa-plus"></i>新增Token</b-button>

        </div>
      </div>

      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="tokens"
                :fields="fields"
              >
                <template #cell(actions)="data">
                  <b-button
                    v-if="checkPermission([consts.TOKEN_LIST_VIEW])"
                    variant="inverse-primary"
                    :to="{
                      name: 'TokenView',
                      params: { tokenId: data.item.id },
                    }"
                  >
                    查看
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.TOKEN_MODIFY])"
                    variant="inverse-warning"
                    :to="{
                      name: 'TokenEdit',
                      params: { tokenId: data.item.id },
                    }"
                  >
                    編輯
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.TOKEN_DELETE])"
                    variant="inverse-danger"
                    @click="deleteToken(data.item.id)"
                  >
                    刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
      </b-card-text>
      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          v-if="totalRows > 0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {mapState} from "vuex";
import tokenApi from "@/apis/token";
import PermissionChecker from '@/utils/PermissionChecker'
import * as consts from '@/consts'
import merchantApi from "@/apis/merchant";
import {format} from "date-fns";

export default {
  data() {
    return {
      consts: consts,
      perPage: 10,
      currentPage: null,
      totalRows: 0,
      showLoading: true,
      initialized: false,
      tokens: [],
      merchants: [],
      orderBy: null,
      orderDirection: null,
      query: {
        page: 1,
        merchant_id: null,
        order_by: null,
        order_direction: null,
      },
      fields: [
        {
          key: "type",
          label: "類型",
          formatter: (value) => {
            // saw_api => SAW, open_api => Open API
            return value === "saw_api" ? "SAW" : "Open API";
          },
        },
        {
          key: "token",
          label: "TOKEN",
          formatter: (value) => value.substring(0, 5) + "*****" + value.substring(value.length - 5),
        },
        {
          key: "memo",
          label: "備註",
          formatter: (value) => {
            if (!value) return "";
            return value.length > 20 ? value.substring(0, 20) + "..." : value;
          },
        },
        {
          key: "expired_at",
          label: "到期日",
          formatter: (value) => {
            return value ? format(new Date(value), "yyyy-MM-dd") : "";
          },
        },
        {
          key: "last_access",
          label: "存取時間",
        },
        {
          key: "actions",
          label: "操作",
        },
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  async created() {
    this.initializeQueryFromRoute();
  },
  watch: {
    'currentPage': function(newPage, oldPage) {
      if (!this.initialized || newPage === oldPage) return;
      this.query.page = newPage;
    },
    'query': {
      handler() {
        if (!this.initialized) return;
        this.updateUrl();
        this.getTokens();
      },
      deep: true
    },
  },
  async mounted() {
    await this.fetchMerchants();
    this.showLoading = false;
  },
  methods: {
    async getTokens() {
      if (!this.initialized) {
        return;
      }
      this.showLoading = true;
      try {
        const params = {
          per_page: this.perPage || 15,
          ...this.query
        };

        const {data} = await tokenApi.getTokens(this.organization, params);
        this.tokens = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "取得合作夥伴資料錯誤",
          });
        }
      }
      this.showLoading = false;
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "全部" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      this.initialized = true;
      await this.getTokens();
    },
    async deleteToken(tokenId) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await tokenApi.removeToken(tokenId);
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除",
              });
              await this.getTokens();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: '錯誤',
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
    checkPermission (permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    initializeQueryFromRoute() {
      const newQuery = {
        ...this.query,
        ...this.$route.query,
      };

      if (newQuery.page) {
        newQuery.page = parseInt(newQuery.page, 10);
      } else {
        newQuery.page = 1;
      }

      this.query = newQuery;
      this.currentPage = newQuery.page;
    },
    updateUrl() {
      // filter out null value
      let query = this.query
      const filteredQuery = Object.keys(query).reduce((acc, key) => {
        if (query[key] !== null && query[key] !== undefined && query[key] !== "") {
          acc[key] = query[key];
        }
        return acc;
      }, {});
      this.$router.push({ path: '', query: filteredQuery }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}
</style>
