var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'OrganizationHome',
            }}},[_vm._v(" 組織資料設定 ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("API Token 管理")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"d-flex flex-column flex-xl-row align-items-start align-items-xl-center justify-content-between mb-4 mb-xl-2"},[_c('h4',{staticClass:"mb-2 mb-xl-0 font-weight-bold"},[_vm._v(" API Token 管理 ")]),_c('div',{staticClass:"\n          d-flex\n          flex-column flex-xl-row\n          align-items-start align-items-xl-center\n        "},[_c('b-form-select',{staticClass:"mr-3 mb-2 mb-xl-0",attrs:{"options":_vm.merchants},model:{value:(_vm.query.merchant_id),callback:function ($$v) {_vm.$set(_vm.query, "merchant_id", $$v)},expression:"query.merchant_id"}}),(_vm.checkPermission([_vm.consts.TOKEN_CREATE]))?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0",attrs:{"variant":"primary","to":{ name: 'TokenCreate', query: { merchant_id: _vm.query.merchant_id } }}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("新增Token")]):_vm._e()],1)]),_c('b-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm"}},[_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.tokens,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [(_vm.checkPermission([_vm.consts.TOKEN_LIST_VIEW]))?_c('b-button',{attrs:{"variant":"inverse-primary","to":{
                    name: 'TokenView',
                    params: { tokenId: data.item.id },
                  }}},[_vm._v(" 查看 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.TOKEN_MODIFY]))?_c('b-button',{attrs:{"variant":"inverse-warning","to":{
                    name: 'TokenEdit',
                    params: { tokenId: data.item.id },
                  }}},[_vm._v(" 編輯 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.TOKEN_DELETE]))?_c('b-button',{attrs:{"variant":"inverse-danger"},on:{"click":function($event){return _vm.deleteToken(data.item.id)}}},[_vm._v(" 刪除 ")]):_vm._e()]}}])})],1)],1)])]),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-top":"80px"}},[(_vm.totalRows > 0)?_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }